import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchTestCaseRunDetail, fetchTestCaseRuns } from "./api";

const initialState = {
  testCaseRuns: [],
  status: "idle",
};

export const fetchTestCaseRunsAsync = createAsyncThunk(
  "testcaseruns/fetchTestCaseRuns",
  async ({ testName }) => {
    const response = await fetchTestCaseRuns({ name: testName });
    return response.data.results;
  }
);

export const fetchTestCaseRunDetailAsync = createAsyncThunk(
  "testcaseruns/fetchTestCaseRunDetail",
  async (testCaseRunID) => {
    const response = await fetchTestCaseRunDetail(testCaseRunID);
    return response.data;
  }
);

export const testCaseRunsSlice = createSlice({
  name: "testcaseruns",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestCaseRunsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTestCaseRunsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.testCaseRuns = action.payload;
      })
      .addCase(fetchTestCaseRunDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTestCaseRunDetailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.testCaseRuns = [
          ...state.testCaseRuns.filter((item) => item.id !== action.payload.id),
          action.payload,
        ];
      });
  },
});

export const selectStatus = (state) => state.testcaseruns.status;

export const selectTestCaseRuns = (state) => state.testcaseruns.testCaseRuns;

export default testCaseRunsSlice.reducer;
