import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';

export default function CopyToClipboard(props) {
    return (
        <Button
            onClick={() => {
                navigator.clipboard.writeText(props.text);
            }}
        >
            <ContentCopyIcon />
        </Button>
    );
}
