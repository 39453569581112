import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    fetchTestCaseRunsAsync,
    selectStatus,
    selectTestCaseRuns,
} from '../testcaseruns/testCaseRunsSlice';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import dayjs from 'dayjs';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

function renderRow({ id, createdAt, detailsLink, testRunLink }) {
    return (
        <TableRow key={id}>
            <TableCell key={`${id}-createdat`}>{createdAt}</TableCell>
            <TableCell key={`${id}-details`}>{detailsLink}</TableCell>
            <TableCell key={`${id}-testrunlink`}>{testRunLink}</TableCell>
        </TableRow>
    );
}

export default function TestDetail() {
    const dispatch = useDispatch();

    const { testName } = useParams();

    const testCaseRuns = Object.values(useSelector(selectTestCaseRuns)).sort(
        (a, b) => dayjs(a.created_at) - dayjs(b.created_at),
    );
    const loadingStatus = useSelector(selectStatus);

    const loadTestCaseRunsByName = () =>
        dispatch(fetchTestCaseRunsAsync({ testName }));

    useEffect(loadTestCaseRunsByName, [dispatch, testName]);
    return (
        <>
            <div>{testName}</div>
            <div>
                <Button
                    disabled={loadingStatus === 'loading'}
                    onClick={loadTestCaseRunsByName}
                >
                    Refresh
                </Button>
                <Line
                    datasetIdKey="id"
                    data={{
                        labels: testCaseRuns.map((testCaseRun) =>
                            dayjs(testCaseRun.created_at),
                        ),
                        datasets: [
                            {
                                id: 1,
                                label: 'Select Query Count',
                                data: testCaseRuns.map(
                                    (testCaseRun) =>
                                        testCaseRun.attributes.selectQueryCount,
                                ),
                                borderColor: 'rgb(255, 99, 132)',
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                id: 1,
                                label: 'Insert Query Count',
                                data: testCaseRuns.map(
                                    (testCaseRun) =>
                                        testCaseRun.attributes.insertQueryCount,
                                ),
                                borderColor: 'rgb(53, 162, 235)',
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                        ],
                        options: {
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Chart.js Line Chart',
                                },
                            },
                        },
                    }}
                />
                <TableContainer>
                    <Table>
                        <TableHead>
                            {renderRow({
                                id: 'ID',
                                createdAt: 'Created At',
                                testRunLink: 'Test Run',
                                detailsLink: 'Details',
                            })}
                        </TableHead>
                        <TableBody>
                            {testCaseRuns.map((testCaseRun) =>
                                renderRow({
                                    id: testCaseRun.id,
                                    createdAt: testCaseRun.created_at,
                                    testRunLink: (
                                        <Link
                                            to={`/runs/${testCaseRun.test_run_id}/`}
                                        >
                                            Test Run
                                        </Link>
                                    ),
                                    detailsLink: (
                                        <Link
                                            to={`/testcaseruns/${testCaseRun.id}/`}
                                        >
                                            Details
                                        </Link>
                                    ),
                                }),
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
