import axios from 'axios';
import { BASE_API_URL } from '../../Consts';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

export function fetchProjects(params) {
    const queryString = new URLSearchParams(omitBy(params, isEmpty));
    return axios.get(
        `${BASE_API_URL}/public/v1/coderepo?${queryString.toString()}`,
        {
            withCredentials: true,
        },
    );
}

export function fetchProjectDetail(id) {
    return axios.get(`${BASE_API_URL}/public/v1/coderepo/${id}`, {
        withCredentials: true,
    });
}
