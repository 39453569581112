import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTestCaseRunsAsync,
  selectStatus,
  selectTestCaseRuns,
} from "./testCaseRunsSlice";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function renderRow({ id, name, detailsLink, testRunLink }) {
  return (
    <TableRow key={id}>
      <TableCell key={`${id}-id`}>{id}</TableCell>
      <TableCell key={`${id}-name`}>{name}</TableCell>
      <TableCell key={`${id}-details`}>{detailsLink}</TableCell>
      <TableCell key={`${id}-testrunlink`}>{testRunLink}</TableCell>
    </TableRow>
  );
}

export default function TestCaseRuns() {
  const dispatch = useDispatch();

  useEffect(() => dispatch(fetchTestCaseRunsAsync({})), [dispatch]);

  const testCaseRuns = useSelector(selectTestCaseRuns);
  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      <div>
        <Button
          disabled={loadingStatus === "loading"}
          onClick={() => dispatch(fetchTestCaseRunsAsync({}))}
        >
          Refresh
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              {renderRow({
                id: "ID",
                name: "Name",
                testRunLink: "Test Run",
                detailsLink: "Details",
              })}
            </TableHead>
            <TableBody>
              {testCaseRuns.map((testCaseRun) =>
                renderRow({
                  id: testCaseRun.id,
                  name: testCaseRun.name,
                  testRunLink: (
                    <Link to={`/runs/${testCaseRun.test_run_id}/`}>
                      Test Run
                    </Link>
                  ),
                  detailsLink: (
                    <Link to={`/testcaseruns/${testCaseRun.id}/`}>Details</Link>
                  ),
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
