import {
    Autocomplete,
    Checkbox,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchProjectsAsync, selectProjects } from '../projects/projectSlice';
import {
    fetchRunsAsync,
    selectRuns,
    selectFetchRunsAsyncStatus,
} from './runsSlice';

function renderRow({
    id,
    key = id,
    checkbox,
    buildNumber,
    gitRef,
    detailsLink,
}) {
    return (
        <TableRow key={key}>
            <TableCell key={`${key}-checkbox`}>{checkbox}</TableCell>
            <TableCell key={`${key}-id`}>{id}</TableCell>
            <TableCell key={`${key}-gitref`}>{gitRef}</TableCell>
            <TableCell key={`${key}-buildnumber`}>{buildNumber}</TableCell>
            <TableCell key={`${key}-detailsLink`}>{detailsLink}</TableCell>
        </TableRow>
    );
}

export default function Runs() {
    const dispatch = useDispatch();

    const [selected, setSelected] = useState([]);
    const [codeRepoID, setCodeRepoID] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(
        () => dispatch(fetchRunsAsync({ codeRepoID: codeRepoID?.id })),
        [dispatch, codeRepoID],
    );
    useEffect(() => dispatch(fetchProjectsAsync()), [dispatch]);

    const projects = useSelector(selectProjects);
    const runs = useSelector(selectRuns);
    const loadingStatus = useSelector(selectFetchRunsAsyncStatus);
    const isLoading = loadingStatus !== 'idle';

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const renderRows = () => {
        return isLoading
            ? Array.from(Array(10).keys()).map((_, index) => {
                  const textSkeleton = <Skeleton variant="text" />;
                  return renderRow({
                      key: index,
                      id: textSkeleton,
                      checkbox: textSkeleton,
                      gitRef: textSkeleton,
                      buildNumber: textSkeleton,
                      detailsLink: textSkeleton,
                  });
              })
            : runs.map((run) =>
                  renderRow({
                      id: run.id,
                      checkbox: (
                          <Checkbox
                              onChange={(event) => handleClick(event, run.id)}
                          />
                      ),
                      gitRef: run.gitRef,
                      buildNumber: run.buildNumber,
                      detailsLink: <Link to={`/runs/${run.id}/`}>Details</Link>,
                  }),
              );
    };

    return (
        <div>
            <div>
                <Button
                    disabled={loadingStatus === 'loading'}
                    onClick={() => dispatch(fetchRunsAsync())}
                >
                    Refresh
                </Button>
                <Button
                    disabled={selected.length !== 2}
                    component={Link}
                    to={`/runscompare?left=${selected[0]}&right=${selected[1]}`}
                >
                    Compare
                </Button>
            </div>
            <div>
                <Autocomplete
                    options={projects.map((project) => {
                        return { label: project.name, id: project.id };
                    })}
                    renderInput={(params) => (
                        <TextField {...params} label="Project" />
                    )}
                    onChange={(event, newValue) => setCodeRepoID(newValue)}
                    value={codeRepoID?.label}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    inputValue={inputValue}
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        {renderRow({
                            id: 'ID',
                            gitRef: 'GitRef',
                            buildNumber: 'Build Number',
                            detailsLink: 'Details',
                        })}
                    </TableHead>
                    <TableBody>{renderRows()}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
