import axios from "axios";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import { BASE_AUTH_URL } from "../../Consts";

export function fetchCollectorCredentials(params) {
  const queryString = new URLSearchParams(omitBy(params, isEmpty));
  return axios.get(
    `${BASE_AUTH_URL}/public/v1/collectorcredentials?${queryString.toString()}`,
    {
      withCredentials: true,
    }
  );
}
