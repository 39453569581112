import {
    Button,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectProjects,
    selectFetchProjectsStatus,
    fetchProjectsAsync,
} from './projectSlice';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

function renderRow({ id, key = id, name, testsLink, tracesLink, detailsLink }) {
    return (
        <TableRow key={key}>
            <TableCell key={`${key}-id`}>{id}</TableCell>
            <TableCell key={`${key}-name`}>{name}</TableCell>
            <TableCell key={`${key}-testsLink`}>{testsLink}</TableCell>
            <TableCell key={`${key}-tracesLink`}>{tracesLink}</TableCell>
            <TableCell key={`${key}-detailsLink`}>{detailsLink}</TableCell>
        </TableRow>
    );
}

export default function Projects() {
    const dispatch = useDispatch();

    useEffect(() => dispatch(fetchProjectsAsync()), [dispatch]);

    const projects = useSelector(selectProjects);
    const loadingStatus = useSelector(selectFetchProjectsStatus);
    const isLoading = loadingStatus !== 'idle';

    const renderRows = () => {
        return isLoading
            ? Array.from(Array(10).keys()).map((_, index) => {
                  const textSkeleton = <Skeleton variant="text" />;
                  return renderRow({
                      key: index,
                      id: textSkeleton,
                      checkbox: textSkeleton,
                      gitRef: textSkeleton,
                      buildNumber: textSkeleton,
                      detailsLink: textSkeleton,
                  });
              })
            : projects.map((project) =>
                  renderRow({
                      id: project.id,
                      name: project.name,
                      testsLink: (
                          <Link to={`/tests/?codeRepoID=${project.id}`}>
                              Link
                          </Link>
                      ),
                      detailsLink: (
                          <Link to={`/projects/${project.id}`}>Details</Link>
                      ),
                  }),
              );
    };

    return (
        <div>
            <Button
                disabled={isLoading}
                onClick={() => dispatch(fetchProjectsAsync())}
            >
                Refresh
            </Button>
            <TableContainer>
                <Table>
                    <TableHead>
                        {renderRow({
                            id: 'ID',
                            name: 'Name',
                            testsLink: 'Tests',
                            detailsLink: 'Details',
                        })}
                    </TableHead>
                    <TableBody>{renderRows()}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
