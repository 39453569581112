import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1C7D71',
        },
        secondary: {
            main: '#AD003D',
        },
        text: {
            primary: 'rgba(45,45,45,0.87)',
        },
    },
});
