import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    compareRunsAsync,
    fetchRunDetailAsync,
    selectCompareRuns,
    selectCompareRunsAsyncStatus,
    selectRunByID,
} from './runsSlice';
import { Link, useSearchParams } from 'react-router-dom';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
    Card,
    CardContent,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';

export default function RunCompare() {
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const leftRunID = searchParams.get('left');
    const rightRunID = searchParams.get('right');
    const leftRun = useSelector(selectRunByID(leftRunID));
    const rightRun = useSelector(selectRunByID(rightRunID));
    const compareRuns = useSelector(selectCompareRuns);

    useEffect(() => {
        if (leftRunID) {
            dispatch(fetchRunDetailAsync(leftRunID));
        }
    }, [dispatch, leftRunID]);

    useEffect(() => {
        if (rightRunID) {
            dispatch(fetchRunDetailAsync(rightRunID));
        }
    }, [dispatch, rightRunID]);

    useEffect(() => {
        if (leftRunID && rightRunID) {
            dispatch(compareRunsAsync({ left: leftRunID, right: rightRunID }));
        }
    }, [dispatch, leftRunID, rightRunID]);

    const compareRunsAsyncStatus = useSelector(selectCompareRunsAsyncStatus);

    const isLoading = compareRunsAsyncStatus !== 'idle';

    const renderRow = ({
        id,
        key = id,
        testName,
        selectSumDiff,
        insertSumDiff,
    }) => {
        return (
            <TableRow key={key}>
                <TableCell key={`${key}-testName`}>{testName}</TableCell>
                <TableCell key={`${key}-selectSumDiff`}>
                    {selectSumDiff}
                </TableCell>
                <TableCell key={`${key}-insertSumDiff`}>
                    {insertSumDiff}
                </TableCell>
            </TableRow>
        );
    };

    const getClassName = (value) => {
        if (value > 0) {
            return 'redCell';
        } else if (value === 0) {
            return 'neutralCell';
        }
        return 'greenCell';
    };

    const renderRunDetail = (run) => {
        return (
            <>
                <Typography variant="h6">
                    {`Project Blah #${run?.buildNumber}`}
                </Typography>
                <Typography noWrap textOverflow="ellipsis" variant="subtitle1">
                    {`ID: ${run?.id}`}
                </Typography>
                <Typography noWrap textOverflow="ellipsis" variant="subtitle1">
                    {`Git ref: ${run?.gitRef}`}
                </Typography>
            </>
        );
    };

    const getRows = () => {
        return isLoading
            ? Array.from(Array(10).keys()).map((_, index) => {
                  const textSkeleton = <Skeleton variant="text" />;
                  return renderRow({
                      key: index,
                      id: textSkeleton,
                      testName: textSkeleton,
                      selectSumDiff: textSkeleton,
                      insertSumDiff: textSkeleton,
                  });
              })
            : compareRuns.map((run, index) =>
                  renderRow({
                      id: index,
                      testName: run.testName,
                      selectSumDiff: (
                          <div className={getClassName(run.selectSumDiff)}>
                              {run.selectSumDiff}
                          </div>
                      ),
                      insertSumDiff: (
                          <div className={getClassName(run.insertSumDiff)}>
                              {run.insertSumDiff}
                          </div>
                      ),
                  }),
              );
    };

    return (
        <div className="runCompare">
            <Typography variant="h5">Test Run Compare</Typography>
            <div className="cardRow">
                <Card className="cardColumn">
                    <CardContent>{renderRunDetail(rightRun)}</CardContent>
                </Card>
                <Tooltip title="Swap">
                    <IconButton
                        component={Link}
                        style={{ height: '1.5em' }}
                        to={`/runscompare?left=${rightRunID}&right=${leftRunID}`}
                    >
                        <CompareArrowsIcon />
                    </IconButton>
                </Tooltip>
                <Card className="cardColumn">
                    <CardContent>{renderRunDetail(leftRun)}</CardContent>
                </Card>
            </div>

            <TableContainer>
                <Table>
                    <TableHead>
                        {renderRow({
                            id: 'head',
                            testName: 'Test Name',
                            selectSumDiff: 'Select Query Count Diff',
                            insertSumDiff: 'Insert Query Count Diff',
                        })}
                    </TableHead>
                    <TableBody>{getRows()}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
