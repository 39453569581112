import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from '../features/projects/projectSlice';
import testsReducer from '../features/tests/testsSlice';
import runsReducer from '../features/runs/runsSlice';
import authReducer from '../features/auth/authSlice';
import testCaseRunReducer from '../features/testcaseruns/testCaseRunsSlice';
import credentialsReducer from '../features/credentials/credentialsSlice';
import LogRocket from 'logrocket';

export const store = configureStore({
    reducer: {
        projects: projectsReducer,
        tests: testsReducer,
        runs: runsReducer,
        auth: authReducer,
        testcaseruns: testCaseRunReducer,
        credentials: credentialsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(LogRocket.reduxMiddleware()),
});
