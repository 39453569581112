import {
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTestsAsync, selectStatus, selectTests } from "./testsSlice";
import { Link, useSearchParams } from "react-router-dom";

function renderRow({ id, name, runsLink, detailsLink }) {
  return (
    <TableRow key={id}>
      <TableCell key={`${id}-name`}>{name}</TableCell>
      <TableCell key={`${id}-runsLink`}>{runsLink}</TableCell>
      <TableCell key={`${id}-detailsLink`}>{detailsLink}</TableCell>
    </TableRow>
  );
}

export default function Tests() {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const codeRepoID = searchParams.get("codeRepoID");

  useEffect(
    () => dispatch(fetchTestsAsync({ codeRepoID })),
    [dispatch, codeRepoID]
  );

  const tests = useSelector(selectTests);
  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      <div>
        <Button
          disabled={loadingStatus === "loading"}
          onClick={() => dispatch(fetchTestsAsync({}))}
        >
          Refresh
        </Button>
        <TableContainer>
          {loadingStatus === "loading" ? (
            <Skeleton variant="rectangle" />
          ) : (
            <Table>
              <TableHead>
                {renderRow({
                  name: "Name",
                  runsLink: "Runs",
                  detailsLink: "Details",
                })}
              </TableHead>
              <TableBody>
                {tests.map((test) =>
                  renderRow({
                    name: test.name,
                    runsLink: (
                      <Button
                        component={Link}
                        to={`/runs/?projectID=${test.id}`}
                      >
                        Link
                      </Button>
                    ),
                    detailsLink: (
                      <Button component={Link} to={`/tests/${test.name}/`}>
                        Details
                      </Button>
                    ),
                  })
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </>
  );
}
