import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchTestsAsync, selectTests } from './testsSlice';
import {
    TableContainer,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableBody,
} from '@mui/material';
import { Link } from 'react-router-dom';

function renderRow({ name, runsLink, detailsLink }, columns, className = null) {
    return (
        <TableRow key={`${name}-row`}>
            {columns.includes('name') && (
                <TableCell key={`${name}-name`} className={className}>
                    {name}
                </TableCell>
            )}
            {columns.includes('runs') && (
                <TableCell key={`${name}-runsLink`} className={className}>
                    {runsLink}
                </TableCell>
            )}
            {columns.includes('details') && (
                <TableCell key={`${name}-detailsLink`} className={className}>
                    {detailsLink}
                </TableCell>
            )}
        </TableRow>
    );
}

export default function TestTable(props) {
    const { filters = {}, classes = {}, columns = ['name', 'details'] } = props;

    const dispatch = useDispatch();

    useEffect(() => dispatch(fetchTestsAsync(filters)), [dispatch, filters]);

    const tests = useSelector(selectTests);

    return (
        <TableContainer className={classes.root}>
            <Table stickyHeader>
                <TableHead>
                    {renderRow(
                        {
                            id: 'ID',
                            name: 'Name',
                            runsLink: 'Runs',
                            detailsLink: 'Details',
                        },
                        columns,
                        classes.header,
                    )}
                </TableHead>
                <TableBody>
                    {tests.map((test) =>
                        renderRow(
                            {
                                name: test.name,
                                detailsLink: (
                                    <Link to={`/tests/${test.name}/`}>
                                        Details
                                    </Link>
                                ),
                            },
                            columns,
                        ),
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
