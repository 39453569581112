import { BASE_API_URL } from '../../Consts';
import axios from 'axios';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

export function fetchTests(params) {
    const queryString = new URLSearchParams(omitBy(params, isEmpty));
    return axios.get(
        `${BASE_API_URL}/public/v1/testcase?${queryString.toString()}`,
        {
            withCredentials: true,
        },
    );
}
