import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from '../../components/CopyToClipboard';
import {
    fetchCollectorCredentialsAsync,
    selectCollectorCredentials,
} from '../credentials/credentialsSlice';
import { selectCurrentUser } from './authSlice';

function renderCollectorCredentialRow(
    { id, clientID, tenantID },
    showCopy = false,
) {
    return (
        <TableRow key={id}>
            <TableCell key={`${id}-id`}>
                {id}
                {showCopy && <CopyToClipboard text={id} />}
            </TableCell>
            <TableCell key={`${id}-clientid`}>{clientID}</TableCell>
            <TableCell key={`${id}-tenantid`}>{tenantID}</TableCell>
        </TableRow>
    );
}

export default function Profile() {
    const dispatch = useDispatch();

    useEffect(() => dispatch(fetchCollectorCredentialsAsync({})), [dispatch]);

    const currentUser = useSelector(selectCurrentUser);
    const collectorCredentials = useSelector(selectCollectorCredentials);

    return (
        <div>
            <div>
                {currentUser ? 'Logged In' : 'Logged Out'}
                <h3>Name</h3>
                <p>{currentUser?.fullName}</p>
                <h3>Email</h3>
                <p>{currentUser?.email}</p>
            </div>
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            {renderCollectorCredentialRow({
                                id: 'ID',
                                tenantID: 'Tenant ID',
                                clientID: 'Client ID',
                            })}
                        </TableHead>
                        <TableBody>
                            {collectorCredentials.map((credential) =>
                                renderCollectorCredentialRow(credential, true),
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
