import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchTests } from './testsAPI';

const initialState = {
    tests: [],
    status: 'idle',
};

export const fetchTestsAsync = createAsyncThunk(
    'tests/fetchTests',
    async ({ codeRepoID, testRunID }) => {
        const response = await fetchTests({
            code_repo_id: codeRepoID,
            test_run_id: testRunID,
        });
        return response.data.results;
    },
);

export const testsSlice = createSlice({
    name: 'tests',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTestsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTestsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tests = action.payload;
            });
    },
});

export const selectStatus = (state) => state.tests.status;

export const selectTests = (state) => state.tests.tests;

export default testsSlice.reducer;
