import axios from "axios";
import { BASE_AUTH_URL } from "../../Consts";

export function login({ email, password }) {
  return axios.post(
    `${BASE_AUTH_URL}/public/v1/login`,
    {
      email: email,
      password: password,
    },
    { withCredentials: true }
  );
}

export function logout() {
  return axios.post(`${BASE_AUTH_URL}/public/v1/logout`, null, {
    withCredentials: true,
  });
}

export function whoami() {
  return axios.get(`${BASE_AUTH_URL}/public/v1/users/whoami`, {
    withCredentials: true,
  });
}
