import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, selectLoginStatus, STATUS_IDLE } from "./authSlice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";

export default function Login(props) {
  const loginStatus = useSelector(selectLoginStatus);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    dispatch(
      loginAsync({
        requestData: { email: email, password: password },
        onSuccess: props.onSubmit,
      })
    );
  };

  return (
    <>
      <div>
        <FormControl variant="standard">
          <InputLabel>Email</InputLabel>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
      </div>
      <div>
        <FormControl
          variant="standard"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <InputLabel>Password</InputLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
      </div>
      <div>
        <Button
          disabled={loginStatus !== STATUS_IDLE}
          variant="contained"
          onClick={handleSubmit}
        >
          Login
        </Button>
      </div>
    </>
  );
}
