import axios from "axios";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import { BASE_API_URL } from "../../Consts";

export function fetchTestCaseRuns(params) {
  const queryString = new URLSearchParams(omitBy(params, isEmpty));
  return axios.get(
    `${BASE_API_URL}/public/v1/testcaserun?${queryString.toString()}`,
    {
      withCredentials: true,
    }
  );
}

export function fetchTestCaseRunDetail(testCaseRunID) {
  return axios.get(`${BASE_API_URL}/public/v1/testcaserun/${testCaseRunID}`, {
    withCredentials: true,
  });
}
