import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login, logout, whoami } from "./authAPI";

export const STATUS_IDLE = "idle";
export const STATUS_LOADING = "loading";

const initialState = {
  loginStatus: STATUS_IDLE,
  logoutStatus: STATUS_IDLE,
  whoamiStatus: STATUS_IDLE,
  currentUser: null,
};

export const loginAsync = createAsyncThunk("auth/login", async (params) => {
  const { requestData, onSuccess, onError } = params;
  const loginResponse = await login(requestData);
  if (loginResponse.status === 200) {
    onSuccess();
    const whoamiResponse = await whoami();
    return whoamiResponse.data;
  }
  if (!!onError) onError();
  return null;
});

export const logoutAsync = createAsyncThunk("auth/logout", async (params) => {
  const response = await logout(params);
  return response.data;
});

export const whoamiAsync = createAsyncThunk("auth/whoami", async (params) => {
  const response = await whoami();
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loginStatus = STATUS_LOADING;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loginStatus = STATUS_IDLE;
        state.currentUser = action.payload;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.loginStatus = STATUS_IDLE;
      })
      .addCase(logoutAsync.pending, (state) => {
        state.logoutStatus = STATUS_LOADING;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.logoutStatus = STATUS_IDLE;
        state.currentUser = null;
      })
      .addCase(logoutAsync.rejected, (state) => {
        state.logoutStatus = STATUS_IDLE;
      })
      .addCase(whoamiAsync.pending, (state) => {
        state.whoamiStatus = STATUS_LOADING;
      })
      .addCase(whoamiAsync.fulfilled, (state, action) => {
        state.whoamiStatus = STATUS_IDLE;
        state.currentUser = action.payload;
      });
  },
});

export const selectLoginStatus = (state) => state.auth.loginStatus;

export const selectCurrentUser = (state) => state.auth.currentUser;

export default authSlice.reducer;
