import axios from 'axios';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { BASE_API_URL } from '../../Consts';

export function fetchRuns(params) {
    const queryString = new URLSearchParams(omitBy(params, isEmpty));
    return axios.get(
        `${BASE_API_URL}/public/v1/testrun?${queryString.toString()}`,
        {
            withCredentials: true,
        },
    );
}

export function fetchRunDetail(id) {
    return axios.get(`${BASE_API_URL}/public/v1/testrun/${id}`, {
        withCredentials: true,
    });
}

export function compareRuns(params) {
    const queryString = new URLSearchParams(omitBy(params, isEmpty));
    return axios.get(
        `${BASE_API_URL}/public/v1/testruncompare?${queryString.toString()}`,
        {
            withCredentials: true,
        },
    );
}
