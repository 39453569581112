import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './features/home/Home';
import Projects from './features/projects/Projects';
import Tests from './features/tests/Tests';
import Runs from './features/runs/Runs';
import ProjectDetail from './features/projects/ProjectDetail';
import TestDetail from './features/tests/TestDetail';
import RunDetail from './features/runs/RunDetail';
import RunCompare from './features/runs/RunCompare';
import TopBar from './features/topbar/TopBar';
import Profile from './features/auth/Profile';
import TestCaseRuns from './features/testcaseruns/TestCaseRuns';
import TestCaseRunDetail from './features/testcaseruns/TestCaseRunDetail';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, whoamiAsync } from './features/auth/authSlice';
import { useEffect } from 'react';
import LogRocket from 'logrocket';

function App() {
    const dispatch = useDispatch();

    useEffect(() => dispatch(whoamiAsync()), [dispatch]);

    const currentUser = useSelector(selectCurrentUser);
    if (currentUser) {
        LogRocket.identify(currentUser.id, {
            name: currentUser.fullName,
            email: currentUser.email,
        });
    }

    const routes = [
        { path: '/', element: <Home /> },
        { path: 'projects', element: <Projects /> },
        { path: 'projects/:projectID', element: <ProjectDetail /> },
        { path: 'tests', element: <Tests /> },
        { path: 'tests/:testName', element: <TestDetail /> },
        { path: 'runs', element: <Runs /> },
        { path: 'runscompare', element: <RunCompare /> },
        { path: 'runs/:runID', element: <RunDetail /> },
        { path: 'profile', element: <Profile /> },
        { path: 'testcaseruns', element: <TestCaseRuns /> },
        { path: 'testcaseruns/:testCaseRunID', element: <TestCaseRunDetail /> },
    ];

    return (
        <div className="app">
            <div>
                <TopBar />
            </div>
            <Paper className="content" elevation={2}>
                <Routes>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={route.element}
                        />
                    ))}
                </Routes>
            </Paper>
        </div>
    );
}

export default App;
