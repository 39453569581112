import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import Login from '../auth/Login';
import { logoutAsync } from '../auth/authSlice';
import Button from '@mui/material/Button';
import { selectCurrentUser } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const pages = [
    {
        name: 'Home',
        route: '/',
    },
    {
        name: 'Projects',
        route: '/projects',
    },
    {
        name: 'Runs',
        route: '/runs',
    },
    {
        name: 'Tests',
        route: '/tests',
    },
    // {
    //     name: 'Test Case Runs',
    //     route: '/testcaseruns',
    // },
];

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const TopBar = () => {
    const dispatch = useDispatch();

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [openLogin, setOpenLogin] = useState(false);

    const currentUser = useSelector(selectCurrentUser);

    const handleOpenLogin = () => {
        handleCloseUserMenu();
        setOpenLogin(true);
    };
    const handleCloseLogin = () => setOpenLogin(false);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        handleCloseUserMenu();
        dispatch(logoutAsync());
    };

    const profileMenuOptions = () => {
        if (currentUser) {
            return (
                <div>
                    <MenuItem
                        key={'profile'}
                        component={Link}
                        to={'/profile'}
                        onClick={handleCloseUserMenu}
                    >
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                    <MenuItem key={'logout'} onClick={handleLogout}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </div>
            );
        }
        return (
            <MenuItem key={'login'} onClick={handleOpenLogin}>
                <Typography textAlign="center">Login</Typography>
            </MenuItem>
        );
    };

    return (
        <>
            <AppBar position="static" className="appBar">
                <Toolbar disableGutters>
                    <Typography
                        variant="h5"
                        noWrap
                        component="div"
                        className="logo"
                    >
                        Testrace
                    </Typography>
                    <div className="appBarTabs">
                        {pages.map((page, index) => (
                            <Button
                                key={page.name}
                                value={index}
                                component={Link}
                                to={page.route}
                                className="tab"
                            >
                                {page.name}
                            </Button>
                        ))}
                    </div>
                    <Tooltip title="Open settings">
                        <IconButton
                            onClick={handleOpenUserMenu}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {profileMenuOptions()}
                    </Menu>
                </Toolbar>
                <Modal
                    open={openLogin}
                    onClose={handleCloseLogin}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Login onSubmit={handleCloseLogin} />
                    </Box>
                </Modal>
            </AppBar>
        </>
    );
};
export default TopBar;
