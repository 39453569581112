import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProjectDetail, fetchProjects } from './projectAPI';

const initialState = {
    projects: [],
    fetchProjectsStatus: 'idle',
    fetchProjectDetailStatus: 'idle',
};

export const fetchProjectsAsync = createAsyncThunk(
    'projects/fetchProject',
    async (params = {}) => {
        const response = await fetchProjects(params);
        return response.data.results;
    },
);

export const fetchProjectDetailAsync = createAsyncThunk(
    'projects/fetchProjectDetail',
    async (id) => {
        const response = await fetchProjectDetail(id);
        return response.data;
    },
);

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsAsync.pending, (state) => {
                state.fetchProjectsStatus = 'loading';
            })
            .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
                state.fetchProjectsStatus = 'idle';
                state.projects = action.payload;
            })
            .addCase(fetchProjectDetailAsync.pending, (state) => {
                state.fetchProjectDetailStatus = 'loading';
            })
            .addCase(fetchProjectDetailAsync.fulfilled, (state, action) => {
                state.fetchProjectDetailStatus = 'idle';
                state.projects = [
                    ...state.projects.filter(
                        (item) => item.id !== action.payload.id,
                    ),
                    action.payload,
                ];
            });
    },
});

export const selectStatus = (state) => state.projects.status;

export const selectFetchProjectDetailStatus = (state) =>
    state.projects.fetchProjectDetailStatus;

export const selectFetchProjectsStatus = (state) =>
    state.projects.fetchProjectsStatus;

export const selectProjectByID = (id) => (state) =>
    state.projects.projects.find((project) => project.id === id);

export const selectProjects = (state) => state.projects.projects;

export default projectsSlice.reducer;
