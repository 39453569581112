import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { compareRuns, fetchRunDetail, fetchRuns } from './runsAPI';

const initialState = {
    runs: [],
    compareRuns: [],
    compareRunsAsyncStatus: 'idle',
    fetchRunsAsyncStatus: 'idle',
    fetchRunDetailAsyncStatus: 'idle',
};

export const compareRunsAsync = createAsyncThunk(
    'runs/compareRuns',
    async (params) => {
        const response = await compareRuns(params);
        return response.data.results;
    },
);

export const fetchRunsAsync = createAsyncThunk(
    'runs/fetchRuns',
    async (params = {}) => {
        const response = await fetchRuns(params);
        return response.data.results;
    },
);

export const fetchRunDetailAsync = createAsyncThunk(
    'runs/fetchRunDetail',
    async (runID) => {
        const response = await fetchRunDetail(runID);
        return response.data;
    },
);

export const runsSlice = createSlice({
    name: 'runs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRunsAsync.pending, (state) => {
                state.fetchRunsAsyncStatus = 'loading';
            })
            .addCase(fetchRunsAsync.fulfilled, (state, action) => {
                state.fetchRunsAsyncStatus = 'idle';
                state.runs = action.payload;
            })
            .addCase(fetchRunDetailAsync.pending, (state) => {
                state.fetchRunDetailAsyncStatus = 'loading';
            })
            .addCase(fetchRunDetailAsync.fulfilled, (state, action) => {
                state.fetchRunDetailAsyncStatus = 'idle';
                state.runs = [
                    ...state.runs.filter(
                        (item) => item.id !== action.payload.id,
                    ),
                    action.payload,
                ];
            })
            .addCase(compareRunsAsync.fulfilled, (state, action) => {
                state.compareRunsAsyncStatus = 'idle';
                state.compareRuns = action.payload;
            })
            .addCase(compareRunsAsync.pending, (state) => {
                state.compareRunsAsyncStatus = 'loading';
            });
    },
});

export const selectCompareRuns = (state) => state.runs.compareRuns;

export const selectCompareRunsAsyncStatus = (state) =>
    state.runs.compareRunsAsyncStatus;

export const selectFetchRunDetailAsyncStatus = (state) =>
    state.runs.fetchRunDetailAsyncStatus;

export const selectFetchRunsAsyncStatus = (state) =>
    state.runs.fetchRunsAsyncStatus;

export const selectRuns = (state) => state.runs.runs;

export const selectRunByID = (runID) => (state) =>
    state.runs.runs.find((run) => run.id === runID);

export default runsSlice.reducer;
