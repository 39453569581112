import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRunDetailAsync, selectRunByID } from './runsSlice';
import { useEffect } from 'react';
import { Card, CardContent, IconButton, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import LinkIcon from '@mui/icons-material/Link';
import {
    fetchProjectDetailAsync,
    selectProjectByID,
} from '../projects/projectSlice';
import TestTable from '../tests/TestTable';

export default function RunDetail() {
    const { runID } = useParams();
    const dispatch = useDispatch();

    useEffect(() => dispatch(fetchRunDetailAsync(runID)), [dispatch, runID]);

    const testRun = useSelector(selectRunByID(runID));

    useEffect(() => {
        if (!testRun?.codeRepoID) {
            return;
        }
        dispatch(fetchProjectDetailAsync(testRun.codeRepoID));
    }, [dispatch, testRun]);

    const project = useSelector(selectProjectByID(testRun?.codeRepoID));

    const createdAt = dayjs(testRun?.createdAt)?.format('YYYY-MM-DD hh:mm');
    const updatedAt = dayjs(testRun?.updatedAt)?.format('YYYY-MM-DD hh:mm');
    const projectName = project ? project.name : '';
    const testRunGitRef = testRun ? testRun.gitRef : '';

    return (
        <div className="runDetail">
            <div className="runDetailHeader">
                <div className="runDetailHeaderTitle">
                    <IconButton
                        href={`https://github.com/${projectName}/commit/${testRunGitRef}`}
                        target="_blank"
                        component={Link}
                    >
                        <LinkIcon />
                    </IconButton>
                    <Typography
                        className="runDetailHeaderTitleText"
                        variant="h5"
                    >{`${projectName} - ${testRunGitRef}`}</Typography>{' '}
                </div>
                <Typography variant="h6">
                    {`Created at: ${createdAt}, Last updated at: ${updatedAt}`}
                </Typography>
            </div>
            <div className="runDetailMetrics">
                <Card className="runDetailMetricsCard">
                    <CardContent>
                        <Typography variant="h6">Total Tests</Typography>
                        <Typography variant="h4">4</Typography>
                    </CardContent>
                </Card>
                <Card className="runDetailMetricsCard">
                    <CardContent>
                        <Typography variant="h6">Total Spans</Typography>
                        <Typography variant="h4">4000</Typography>
                    </CardContent>
                </Card>
                <Card className="runDetailMetricsCard">
                    <CardContent>
                        <Typography variant="h6">Outlier Events</Typography>
                        <Typography variant="h4">1</Typography>
                    </CardContent>
                </Card>
            </div>
            <TestTable
                classes={{
                    root: 'runDetailTests',
                }}
                filters={{ testRunID: testRun?.id }}
            />
        </div>
    );
}
